<template>
	<div class="container lg:mx-auto lg:flex lg:items-end lg:justify-center">
		<header class="flex items-center justify-between mx-4 py-6 lg:hidden">
			<NuxtLink to="/">
				<img src="/images/logo.svg" alt="StreamersClub" />
			</NuxtLink>
			<button @click="() => (isOpenMenu = true)" title="Abrir menu">
				<Menu class="text-white w-9 h-9" />
			</button>
		</header>

		<div class="rounded-t-2xl px-4 lg:px-0 lg:mt-8 mb-8">
			<HeaderDesktop
				ref="headerRef"
				:items="headerItems"
				class="header mx-28 lg:sticky lg:z-50 lg:top-16 lg:-mb-40 transition-transform duration-500"
			/>

			<div class="space-y-12 lg:space-y-20 lg:mt-20">
				<section class="pb-8" id="mainHero">
					<div class="relative">
						<div class="h-1/2 w-full absolute -bottom-8 bg-[#404B1D] rounded-2xl -z-10"></div>
						<div class="h-1/2 w-full absolute -bottom-4 bg-[#272F11] rounded-2xl -z-10"></div>

						<div
							class="py-11 rounded-2xl relative z-20"
							style="background-image: url(/images/mask-bg-hero-streamer.png)"
						>
							<div class="hidden lg:block h-20"></div>

							<div class="lg:grid lg:grid-cols-2 lg:mx-28 lg:gap-8 lg:mt-8">
								<div class="lg:flex lg:flex-col lg:justify-center text-white">
									<h1
										class="font-semibold text-2xl font-jakarta text-center lg:text-start lg:text-4xl"
									>
										Aumente sua <span class="text-[#CEF26F] font-extrabold">receita</span> com itens
										personalizados para sua
										<span class="text-[#CEF26F] font-extrabold">comunidade.</span>
									</h1>

									<p
										class="font-inter my-6 w-4/5 mx-auto text-center lg:text-start lg:mx-0 lg:text-lg"
									>
										É rápido, fácil e gratuito! Vamos começar?
									</p>

									<Button
										href="/join"
										class="w-9/12 lg:w-fit mx-auto lg:mx-0 lg:px-24 text-black"
										title="Começar agora"
									>
										Começar agora
									</Button>
								</div>

								<LandingPageVideoCard
									class="mt-6 lg:mt-0 rounded-xl"
									videoClass="rounded-xl"
									:autoplay="true"
									videoSrc="https://cdn.streamersclub.com.br/assets/videos/unboxing/nimayumi.mp4"
									thumbnailSrc="https://cdn.streamersclub.com.br/assets/videos/thumbnails/nimayumi.jpg"
								/>
							</div>
						</div>
					</div>
				</section>

				<section class="grid grid-cols-1 lg:grid-cols-2 gap-16" id="about" ref="aboutRef">
					<div class="h-full">
						<div class="sticky top-0 pt-5 flex flex-col items-center md:items-start">
							<IconsThreeSparks class="mb-6" />
							<h1 class="text-white font-jakarta text-4xl mb-9 font-bold lg:text-left text-center">
								O que é a StreamersClub?
							</h1>
							<h2 class="text-white/80 text-xl mb-6 text-center md:text-left">
								<b class="text-[#CEF26F]"
									>Nós ajudamos você a aumentar sua receita de maneira fácil e prática vendendo
									produtos personalizados de alta qualidade para a sua comunidade.</b
								>
								<br /><br />
								<b class="text-white">Nós cuidamos de tudo:</b> Design, produção e entrega dos produtos
								e caixas personalizadas. <br /><br />
								<b class="text-white">Gratuito:</b> Não cobramos nada de você, todos os pedidos são
								feito sob demanda.<br /><br />
								<b class="text-white">Foque naquilo que você faz de melhor:</b> Produzir conteúdo e se
								conectar com sua comunidade. <br /><br />
							</h2>
							<!-- <Button variant="primary" class="w-fit px-12" href="/join">Criar meu club</Button> -->
						</div>
					</div>
					<div class="space-y-6">
						<div
							class="rounded-2xl p-6 h-[475px] flex flex-col justify-between sticky top-5"
							v-for="item in infoItems"
							:class="item.wrapperClass"
							:key="item.title"
						>
							<h1
								:class="
									twMerge(
										'text-[#050607] font-jakarta font-bold text-3xl text-center lg:text-left',
										item.titleClass || ''
									)
								"
							>
								{{ item.title }}
							</h1>

							<component :is="item.icon" class="mx-auto w-[200px] h-[200px]" />

							<p
								:class="
									twMerge(
										'text-[#050607]/60 font-bold text-lg text-center lg:text-left',
										item.descriptionClass || ''
									)
								"
								v-html="item.description"
							></p>
						</div>
					</div>
				</section>

				<section id="gifts">
					<div class="lg:px-28 bg-[#141808] relative px-4 py-20 rounded-2xl">
						<img
							:src="`/images/landing-page/light-effect-bg${device.isMobile ? '-mobile' : ''}.png`"
							alt="light effect"
							class="pointer-events-none rounded-2xl absolute top-0 left-0 w-full h-fit object-cover"
						/>

						<h1
							class="font-jakarta font-extrabold text-3xl py-6 text-white text-center lg:text-6xl lg:font-bold"
						>
							Caixas personalizadas?
						</h1>

						<p class="w-3/4 mx-auto text-white mb-8 text-center lg:text-2xl">
							Além de vender produtos personalizados, você pode vender caixas personalizadas para seus
							fãs!<br />
						</p>

						<div class="grid gap-6 grid-cols-12 lg:gap-x-8 lg:gap-y-10">
							<IconsLandingPageRiskIcon class="col-span-12 hidden lg:block" />
							<div class="col-span-12 lg:col-span-5 h-full relative">
								<div class="flex justify-between items-center mb-3 lg:hidden">
									<IconsLandingPageRiskIcon />
									<IconsLandingPageHeartIcon />
								</div>

								<img
									class="rounded-2xl w-full lg:h-full"
									src="/images/banner-01.jpg"
									alt="streamers club banner"
								/>
							</div>

							<div class="col-span-12 lg:col-span-7 lg:flex lg:flex-col">
								<div>
									<IconsLandingPageHeartIcon class="hidden lg:block mb-6" />
									<div class="bg-[#272F13] w-full rounded-2xl">
										<p class="px-6 text-[#CEE474] font-bold text-xl py-4 lg:text-2xl">
											Cada Streamer tem sua própria box com seu próprio tema. <br />
										</p>
									</div>
								</div>

								<div
									class="flex-1 bg-[#272F13] w-full rounded-2xl py-10 px-4 mt-6 flex justify-between flex-col"
								>
									<h1 class="text-white font-jakarta font-bold text-3xl mb-8 lg:mb-6">
										Uma experiência inesquecivel
									</h1>

									<p class="text-white/80 text-lg lg:text-xl !leading-normal mb-4">
										A caixa da StreamersClub é montada especialmente para você e sua comunidade.
									</p>
									<p class="text-white/80 text-lg lg:text-xl !leading-normal mb-4">
										Contém produtos de alta qualidade, design exclusivo e feitos sob demanda.
									</p>
									<p class="text-white/80 text-lg lg:text-xl !leading-normal">
										Focada em cada detalhe para maximizar a experiência de quem a recebe.
									</p>
								</div>
							</div>

							<!-- <p class="font-bold text-white text-xl lg:text-3xl col-span-full text-center">
								Várias opções para os seus fãs!
							</p>

							<div class="grid grid-cols-1 gap-6 lg:grid-cols-3 col-span-12">
								<BoxCard
									v-for="box in sortedBoxes"
									:key="box._id"
									:box="box"
									:disableBuy="true"
									@buyClick="navigateTo('/streamers')"
								/>
							</div> -->

							<div
								class="col-span-12 bg-[#272F13] w-full rounded-2xl py-6 px-4 lg:col-span-12 lg:flex lg:justify-between lg:items-center"
							>
								<p class="text-base font-jakarta text-white lg:text-lg">
									<span class="text-[#CEF26F] font-bold">Gostou?</span>
									Junte-se ao Club e venda sua própria Box.<br />
								</p>

								<Button
									href="/join"
									class="mt-6 lg:mt-0 text-sm w-full md:w-fit px-7"
									title="Criar seu club"
									>Criar seu club</Button
								>
							</div>

							<!-- <template v-if="highlightedStreamer">
								<div class="col-span-12 grid grid-template grid-cols-1 lg:grid-cols-2 gap-8 mt-16">
									<div class="lg:flex lg:flex-col lg:justify-center col1 text-white">
										<h1
											class="font-jakarta font-extrabold text-3xl text-center lg:text-start lg:text-5xl"
										>
											Vem dar<br />
											uma olhadinha!
										</h1>

										<Button
											class="w-fit h-[48px] bg-[#CEF26F] text-black px-5 text-sm gap-2 items-center mx-auto hidden mt-6 lg:flex lg:mr-auto lg:ml-0"
											href="/join"
											title="Também quero!"
										>
											<span>Também quero!</span>
										</Button>
									</div>

									<LandingPageVideoCard
										class="col2 mx-0 rounded-xl"
										videoClass="rounded-xl"
										videoSrc="https://cdn.streamersclub.com.br/assets/videos/unboxing/nimayumi.mp4"
										thumbnailSrc="https://cdn.streamersclub.com.br/assets/videos/thumbnails/nimayumi.jpg"
										:autoplay="true"
									/>
								</div>
								<div class="mt-6 lg:hidden col-span-12">
									<Button
										variant="black"
										class="w-full lg:w-fit h-[48px] bg-[#CEF26F] text-black px-5 text-sm flex gap-2 items-center md:mx-auto"
										href="/join"
										title="Também quero!"
									>
										<span>Também quero!</span>
									</Button>
								</div>
							</template> -->
						</div>
					</div>
				</section>

				<section id="be-part" ref="bePartRef">
					<div class="py-20 bg-white rounded-2xl text-center px-4 lg:px-28">
						<h1 class="font-jakarta font-extrabold text-3xl py-6 lg:text-6xl mb-8">
							Por quê a StreamersClub <br class="hidden md:block" />
							é única?
						</h1>

						<IconsLandingPageLoveIllustration class="mx-auto my-12 w-full" />

						<div class="grid grid-cols-1 gap-6 lg:gap-8 md:grid-cols-2 lg:grid-cols-3">
							<div
								class="border border-[#D8E3FF] py-6 rounded-2xl"
								v-for="item in aboutItems"
								:key="item.title"
								:class="item.class || ''"
							>
								<span :class="twMerge(item.icon, 'mx-auto text-[76px] w-fit')"></span>
								<h1 class="font-semibold text-lg mt-6 mb-4">
									{{ item.title }}
								</h1>
								<p class="text-sm text-[#18181B]/80 mx-6">
									{{ item.description }}
								</p>
							</div>
						</div>

						<div class="relative bg-[#141808] rounded-2xl mt-24 p-6 pt-64 lg:pt-6">
							<div
								class="border-mask lg:w-1/2 text-center font-bold lg:font-normal lg:text-left p-6 pt-2 relative"
							>
								<IconsLandingPageRiskIcon class="w-10" />
								<IconsLandingPageRiskIcon
									class="w-10 absolute lg:block bottom-20 right-4 lg:bottom-6 lg:right-1/4 rotate-180"
								/>
								<p class="text-white text-xl lg:text-3xl font-jakarta mb-20 lg:mb-4">
									Preparado para criar <br />
									o seu <b>CLUB</b>
								</p>
								<Button
									href="/join"
									class="bg-[#CCF169] h-[48px] text-black w-full lg:w-fit px-9 text-sm"
									>Criar agora</Button
								>
							</div>

							<img
								class="absolute -top-16 left-1/2 -translate-x-1/2 lg:bottom-0 lg:right-0 lg:translate-x-0 lg:left-auto"
								src="/images/landing-page/streamer-banner-illustration.png"
								alt="Illustration"
							/>
						</div>
					</div>
				</section>

				<section class="!mt-8 px lg:px-0" id="partners">
					<p
						v-if="streamers?.results.length"
						class="bg-[#F2F2F2] rounded-2xl p-3 w-fit font-bold text-base mx-auto"
					>
						Parceiros
					</p>

					<h1
						v-if="streamers?.results.length"
						class="text-white text-center mt-6 mb-10 font-extrabold text-3xl font-jakarta lg:text-6xl"
					>
						Quem já faz parte <br />
					</h1>

					<div v-if="streamers?.results.length" class="relative flex overflow-x-hidden max-w-[1200px]">
						<div
							class="bg-gradient-to-r from-[#050607] to-transparent w-1/3 absolute -left-1 top-0 h-full z-20 pointer-events-none"
						></div>

						<div
							class="bg-gradient-to-l from-[#050607] to-transparent w-1/3 absolute -right-1 top-0 h-full z-20 pointer-events-none"
						></div>

						<div class="animate-marquee whitespace-nowrap flex">
							<LandingPageMarqueeStreamerCard :streamer="streamer" v-for="streamer in marqueeStreamers" />
						</div>

						<div class="absolute top-0 animate-marquee2 whitespace-nowrap flex">
							<LandingPageMarqueeStreamerCard :streamer="streamer" v-for="streamer in marqueeStreamers" />
						</div>
					</div>
				</section>

				<section id="faq">
					<FaqAccordion accordionType="multiple" accordionItemClass="border-b-zinc-900" :items="faqItems" />
				</section>
			</div>
		</div>
	</div>
	<HeaderMobile
		:showControls="false"
		@onToggle="value => (isOpenMenu = value)"
		:is-open="isOpenMenu"
		:items="headerItems"
	/>
</template>

<script setup lang="ts">
import IconsGroupIllustration from '~/components/icons/GroupIllustration.vue'
import IconsShippingIllustration from '~/components/icons/ShippingIllustration.vue'
import IconsShopIllustration from '~/components/icons/ShopIllustration.vue'

import { twMerge } from 'tailwind-merge'
import type { IBox } from '~/types/models/box.interface'
import type { IUser } from '~/types/models/user.interface'
import { Menu } from 'lucide-vue-next'
import type { IPagination } from '~/types/pagination.interface'

const { device } = useDeviceStore()

const observers = ref<IntersectionObserver[]>([])
const boxes = ref<IBox[]>([])
const isOpenMenu = ref(false)
const headerRef = ref<{ ref: HTMLElement } | null>(null)
const aboutRef = ref<HTMLElement | null>(null)
const bePartRef = ref<HTMLElement | null>(null)
const aboutItems = [
	{
		icon: 'fi fi-tr-badget-check-alt',
		title: 'Alta qualidade',
		description: 'Produtos de alta qualidade, com design exclusivo e feitos sob demanda.',
	},
	{
		icon: 'fi fi-ts-padlock-check',
		title: 'Seguro',
		description:
			'Todas as informacoes sensíveis são criptografadas, você e sau comunidade não precisam se preocupar com nada.',
	},
	{
		icon: 'fi fi-ts-heart-partner-handshake',
		title: 'Te ajudamos a crescer',
		description: 'Nós ajudamos você a vender os produtos com anúncios e divulgações em nossas redes sociais.',
		class: 'md:col-span-2 lg:col-span-1',
	},
]
const infoItems = [
	{
		title: 'Crie seu Club',
		description: `<b class="text-black">Produza ou trabalhe conosco</b> no design dos produtos</b> presentes na loja e na caixa personalizada.`,
		wrapperClass: 'bg-white',
		icon: IconsShopIllustration,
	},
	{
		title: 'Fabricação',
		description: `Não se preocupe, <b class="text-[#CEF26F]">cuidamos de tudo para você</b>. Com nosso processo focado em qualidade, garantimos que tudo será feito com carinho.`,
		wrapperClass: 'bg-[#18191B] text-white',
		titleClass: 'text-white',
		descriptionClass: 'text-zinc-400',
		icon: IconsShippingIllustration,
	},
	{
		title: 'Entrega',
		description: `<b class="text-black">O envio também é nossa responsabilidade</b>. Garantimos que os produtos cheguem em perfeitas condições e no prazo certo.`,
		wrapperClass: 'bg-[#CEF26F]',
		icon: IconsGroupIllustration,
	},
]
const headerItems = [
	{
		label: 'Sobre',
		action: () => scrollToElement('about'),
	},
	{
		label: 'Caixas',
		action: () => scrollToElement('gifts'),
	},
	{
		label: 'FAQ',
		action: () => scrollToElement('faq'),
	},
	{
		label: 'Membros',
		href: '/streamers',
	},
]

const faqItems = [
	{
		title: 'Como eu recebo minha comissão?',
		content: `<p class="pb-4 pt-0 font-jakarta text-white/80">
            É simples! Sempre que você realizar uma venda de um item ou caixa personalizada, sua comissão será automaticamente depositada no Stripe. A partir de lá, o valor será transferido para sua conta bancária em alguns dias.
        </p>`,
		html: true,
	},
	{
		title: 'Tenho algum custo para começar?',
		content: `<p class="pb-4 pt-0 font-jakarta text-white/80">
            Não! Criar o seu clube é totalmente gratuito, assim como a produção e o envio dos seus itens personalizados! Tudo custeado por nós.
        </p>`,
		html: true,
	},
	{
		title: 'Como faço para criar o clube?',
		content: `<p class="pb-4 pt-0 font-jakarta text-white/80">
            Rápido e simples! Primeiro, <a href="/join" class="text-[#CEF26F] font-bold">inscreva-se aqui</a>. Em seguida, um membro da nossa equipe entrará em contato para orientá-lo na criação do seu clube. Depois, basta seguir estes 3 passos: 1. Preencher seu perfil, 2. Configurar sua box, 3. Vincular seu Stripe (onde você recebe suas comissões).
        </p>`,
		html: true,
	},
	{
		title: 'Eu preciso ter artes prontas?',
		content: `<p class="pb-4 pt-0 font-jakarta text-white/80">
            Caso você não tenha elas prontas, a gente consegue te ajudar com isso! Não há nenhum custo, e o processo é bem simples. Basta acessar o seu painel de afiliado, ir até o menu "Configurar Box" e clicar no botão "Solicitar Arte Personalizada".
        </p>`,
		html: true,
	},
	{
		title: 'Por onde posso entrar em contato?',
		content: `<p class="pb-4 pt-0 font-jakarta text-white/80">
            Disponibilizamos 3 canais de contato para qualquer dúvida ou suporte: 1. DM no Instagram, 2. E-mail: contato@streamersclub.com.br ou 3. <a class="text-[#CEF26F] font-bold" href="https://api.whatsapp.com/send?phone=+5511993217923&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20minha%20conta%20no%20Streamers%20Club" target="_blank">WhatsApp</a>.
        </p>`,
		html: true,
	},
]

interface Streamer extends IUser {
	boxes: (IBox & { price: number })[]
}

const streamers = ref<IPagination<Streamer> | undefined>(undefined)
streamers.value = await useApi('/streamers?limit=10')

function scrollToElement(id: string) {
	const element = document.getElementById(id)

	if (element) {
		element.scrollIntoView({ behavior: 'smooth' })
	}

	if (device.isMobile) {
		isOpenMenu.value = false
	}
}

const marqueeStreamers = computed(() => {
	const results = streamers.value?.results ?? []

	if (results.length <= 4) {
		return [...results, ...results, ...results, ...results]
	}

	return results
})

onMounted(() => {
	if (aboutRef.value) {
		observers.value[0] = new IntersectionObserver(
			(entries: IntersectionObserverEntry[]) => {
				entries.forEach(entry => {
					const header = headerRef.value?.ref as HTMLElement

					if (entry.isIntersecting) {
						header.style.transform = 'translateY(-500%)'
					} else {
						header.style.transform = 'translateY(0)'
					}
				})
			},
			{
				root: null,
				threshold: 0.2,
			}
		)

		observers.value[0].observe(aboutRef.value)
	}

	if (bePartRef.value) {
		observers.value[1] = new IntersectionObserver(
			(entries: IntersectionObserverEntry[]) => {
				entries.forEach(entry => {
					const header = headerRef.value?.ref as HTMLElement

					if (entry.isIntersecting) {
						header.classList.add('is-black')
					} else {
						header.classList.remove('is-black')
					}
				})
			},
			{
				root: null,
				threshold: 0.3,
			}
		)

		observers.value[1].observe(bePartRef.value)
	}
})

onBeforeUnmount(() => {
	observers.value.forEach(observer => observer.disconnect())
})

useSeoMeta({
	title: 'Aumente sua receita vendendo produtos e caixas personalizadas',
	description:
		'Conecte-se com seus fãs e ofereça caixas personalizadas com produtos exclusivos. Crie seu club para aumentar sua receita e fortalecer sua comunidade.',
	robots: 'index, follow',
})
</script>

<style scoped>
.header > * {
	@apply bg-[#CDF465]/20 lg:backdrop-blur-sm;
}

.header:is(.is-black) > * {
	@apply bg-[#1E1E1E]/80;
}

@media (min-width: 1024px) {
	.border-mask {
		background-image: url(/images/landing-page/badge-border.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.grid-template {
		grid-template-areas: 'col2 col2 col1';
	}

	.col2 {
		grid-area: col2;
	}

	.col1 {
		grid-area: col1;
	}
}
</style>
