<template>
	<svg width="164" height="163" viewBox="0 0 164 163" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1399_760)">
			<path
				d="M133.935 37.5176H11.4742V142.985H133.935V37.5176Z"
				fill="white"
				stroke="black"
				stroke-width="2.80901"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M94.1018 142.666V99.0486C94.1018 91.7826 88.2118 85.8926 80.9458 85.8926H64.463C57.1971 85.8926 51.3071 91.7826 51.3071 99.0486V142.666"
				stroke="black"
				stroke-width="2.80901"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M138.191 29.9399V42.0467C138.191 54.0964 128.446 63.9457 116.397 63.9457C104.348 63.9457 94.4987 54.0964 94.4987 42.0467C94.4987 54.0964 84.7542 63.9457 72.7045 63.9457C60.6553 63.9457 51.0153 54.2013 50.9113 42.1516C50.9113 54.2013 41.062 64.0506 29.0123 64.0506C16.9626 64.0506 7.2182 54.2013 7.2182 42.1516V29.9399C7.2182 20.1955 15.1813 12.2324 24.9257 12.2324H120.483C130.228 12.2324 138.191 20.196 138.191 29.9399Z"
				fill="white"
				stroke="black"
				stroke-width="2.80901"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M135.718 161.593H9.6909C5.11691 161.593 1.40948 157.886 1.40948 153.312V151.264C1.40948 146.69 5.11737 142.982 9.6909 142.982H135.718C140.292 142.982 144 146.69 144 151.264V153.312C144 157.886 140.292 161.593 135.718 161.593Z"
				fill="white"
				stroke="black"
				stroke-width="2.80901"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M144.83 71.7862H94.1937C84.8795 71.7862 77.3289 64.2356 77.3289 54.9214V18.2671C77.3289 8.95295 84.8795 1.40234 94.1937 1.40234H144.83C154.144 1.40234 161.695 8.95295 161.695 18.2671V54.9214C161.695 64.2356 154.144 71.7862 144.83 71.7862Z"
				fill="white"
				stroke="black"
				stroke-width="2.80901"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M123.61 45.2726H107.843C106.198 45.2726 104.714 44.2871 104.075 42.7712L99.637 32.2304C98.5027 29.5361 100.481 26.5566 103.404 26.5566H128.049C130.972 26.5566 132.95 29.5361 131.816 32.2304L127.378 42.7712C126.739 44.2871 125.255 45.2726 123.61 45.2726Z"
				stroke="black"
				stroke-width="2.80901"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M108.341 57.9403C108.712 57.9403 109.013 57.6393 109.013 57.268C109.013 56.8967 108.712 56.5957 108.341 56.5957C107.969 56.5957 107.668 56.8967 107.668 57.268C107.668 57.6393 107.969 57.9403 108.341 57.9403Z"
				stroke="black"
				stroke-width="2.80901"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M124.04 57.9403C124.412 57.9403 124.713 57.6393 124.713 57.268C124.713 56.8967 124.412 56.5957 124.04 56.5957C123.669 56.5957 123.368 56.8967 123.368 57.268C123.368 57.6393 123.669 57.9403 124.04 57.9403Z"
				stroke="black"
				stroke-width="2.80901"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M131.816 32.2303L134.741 25.3285C135.786 22.8608 138.206 21.2578 140.887 21.2578H142.831"
				stroke="black"
				stroke-width="2.80901"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1399_760">
				<rect width="163.095" height="163" fill="white" transform="translate(0.00457764)" />
			</clipPath>
		</defs>
	</svg>
</template>
