<template>
	<svg width="206" height="114" viewBox="0 0 206 114" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1399_780)">
			<path
				d="M204.367 100.207C204.367 106.837 198.854 112.213 192.051 112.213H138.697C131.893 112.213 126.38 106.837 126.38 100.207C126.38 82.851 140.819 68.7754 158.628 68.7754H172.125C189.928 68.7754 204.367 82.8457 204.367 100.207Z"
				fill="#CEF26F"
				stroke="black"
				stroke-width="3.26483"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M165.374 52.8624C177.368 52.8624 187.09 43.1397 187.09 31.146C187.09 19.1524 177.368 9.42969 165.374 9.42969C153.38 9.42969 143.658 19.1524 143.658 31.146C143.658 43.1397 153.38 52.8624 165.374 52.8624Z"
				fill="#CEF26F"
				stroke="black"
				stroke-width="3.26483"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M79.6251 100.207C79.6251 106.837 74.1118 112.213 67.3083 112.213H13.9494C7.14597 112.213 1.63263 106.837 1.63263 100.207C1.63263 82.851 16.0716 68.7754 33.8807 68.7754H47.3771C65.1862 68.7754 79.6251 82.8457 79.6251 100.207Z"
				fill="#CEF26F"
				stroke="black"
				stroke-width="3.26483"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M40.6262 52.8624C52.6198 52.8624 62.3426 43.1397 62.3426 31.146C62.3426 19.1524 52.6198 9.42969 40.6262 9.42969C28.6326 9.42969 18.9099 19.1524 18.9099 31.146C18.9099 43.1397 28.6326 52.8624 40.6262 52.8624Z"
				fill="#CEF26F"
				stroke="black"
				stroke-width="3.26483"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M144.99 98.8489C144.99 105.989 139.05 111.782 131.726 111.782H74.2647C66.9399 111.782 61 105.995 61 98.8489C61 80.1551 76.5501 65 95.7283 65H110.262C129.44 65.0053 144.99 80.1551 144.99 98.8489Z"
				fill="#CEF26F"
				stroke="black"
				stroke-width="3.26483"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M103 48.4127C115.918 48.4127 126.391 37.9402 126.391 25.0218C126.391 12.1033 115.918 1.63086 103 1.63086C90.0812 1.63086 79.6088 12.1033 79.6088 25.0218C79.6088 37.9402 90.0812 48.4127 103 48.4127Z"
				fill="#CEF26F"
				stroke="black"
				stroke-width="3.26483"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1399_780">
				<rect width="206" height="113.848" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
