<template>
	<svg width="484" height="439" viewBox="0 0 484 439" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1680_4025)">
			<path
				d="M421.186 333.398C436.285 333.398 448.526 321.157 448.526 306.058C448.526 290.959 436.285 278.719 421.186 278.719C406.087 278.719 393.847 290.959 393.847 306.058C393.847 321.157 406.087 333.398 421.186 333.398Z"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path d="M393.825 306.057H448.526" stroke="#231F20" stroke-width="1.08749" stroke-miterlimit="10" />
			<path d="M421.186 278.696V333.397" stroke="#231F20" stroke-width="1.08749" stroke-miterlimit="10" />
			<path
				d="M421.186 333.398C421.186 333.398 430.299 321.305 430.299 306.058C430.299 290.812 421.186 278.719 421.186 278.719"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M421.186 333.398C421.186 333.398 439.413 327.286 439.413 306.058C439.413 284.809 421.186 278.719 421.186 278.719"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M421.186 333.398C421.186 333.398 412.073 321.305 412.073 306.058C412.073 290.812 421.186 278.719 421.186 278.719"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M421.186 333.398C421.186 333.398 402.96 327.286 402.96 306.058C402.96 284.809 421.186 278.719 421.186 278.719"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M396.718 318.303C399.632 317.041 407.462 314.388 421.186 314.388C434.91 314.388 442.719 317.063 445.655 318.303"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M403.177 326.633C406.027 325.285 411.616 323.479 421.186 323.479C430.669 323.479 436.259 325.263 439.13 326.59"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M396.718 293.812C399.632 295.074 407.462 297.727 421.186 297.727C434.91 297.727 442.719 295.052 445.655 293.812"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M403.177 285.459C406.027 286.807 411.616 288.613 421.186 288.613C430.669 288.613 436.259 286.829 439.13 285.502"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M297.603 289.361C400.547 217.348 459.106 123.383 428.397 79.4846C397.689 35.5863 289.342 58.3781 186.397 130.391C83.453 202.405 24.8941 296.37 55.6026 340.268C86.311 384.166 194.658 361.375 297.603 289.361Z"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M116.82 129.255H405.57V352.538C405.57 359.258 400.132 364.696 393.412 364.696H128.978C122.258 364.696 116.82 359.258 116.82 352.538V129.255Z"
				fill="white"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M128.978 76.3594H393.412C400.132 76.3594 405.57 81.7968 405.57 88.5175V129.276H116.82V88.5175C116.82 81.7968 122.258 76.3594 128.978 76.3594Z"
				fill="#231F20"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M149.467 111.158C154.2 111.158 158.036 107.419 158.036 102.806C158.036 98.1934 154.2 94.4541 149.467 94.4541C144.734 94.4541 140.897 98.1934 140.897 102.806C140.897 107.419 144.734 111.158 149.467 111.158Z"
				fill="white"
				stroke="white"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M179.112 111.158C183.844 111.158 187.681 107.419 187.681 102.806C187.681 98.1934 183.844 94.4541 179.112 94.4541C174.379 94.4541 170.542 98.1934 170.542 102.806C170.542 107.419 174.379 111.158 179.112 111.158Z"
				fill="white"
				stroke="white"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M208.756 111.158C213.489 111.158 217.326 107.419 217.326 102.806C217.326 98.1934 213.489 94.4541 208.756 94.4541C204.024 94.4541 200.187 98.1934 200.187 102.806C200.187 107.419 204.024 111.158 208.756 111.158Z"
				fill="white"
				stroke="white"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M261.195 307.95C294.865 307.95 322.16 280.656 322.16 246.986C322.16 213.316 294.865 186.021 261.195 186.021C227.525 186.021 200.231 213.316 200.231 246.986C200.231 280.656 227.525 307.95 261.195 307.95Z"
				fill="white"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M301.933 261.167C296.06 278.001 280.009 290.116 261.195 290.116C242.382 290.116 226.352 278.001 220.458 261.167"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M297.93 260.405C297.93 260.405 301.323 262.232 305.282 261.906"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M224.134 260.405C224.134 260.405 220.741 262.232 216.782 261.906"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M276.42 250.532C281.814 250.532 286.186 241.3 286.186 229.913C286.186 218.525 281.814 209.294 276.42 209.294C271.027 209.294 266.655 218.525 266.655 229.913C266.655 241.3 271.027 250.532 276.42 250.532Z"
				fill="#231F20"
			/>
			<path
				d="M245.971 250.532C251.364 250.532 255.736 241.3 255.736 229.913C255.736 218.525 251.364 209.294 245.971 209.294C240.577 209.294 236.205 218.525 236.205 229.913C236.205 241.3 240.577 250.532 245.971 250.532Z"
				fill="#231F20"
			/>
			<path
				d="M351.043 92.366H322.486L313.59 99.5434L304.695 92.366H276.137C274.376 92.366 272.94 90.9305 272.94 89.1688V28.1826C272.94 26.4208 274.376 24.9854 276.137 24.9854H351.043C352.805 24.9854 354.241 26.4208 354.241 28.1826V89.1688C354.241 90.9523 352.805 92.366 351.043 92.366Z"
				fill="white"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M334.122 45.4301C325.27 33.9897 313.59 48.0183 313.59 48.0183C313.59 48.0183 301.911 34.0115 293.059 45.4301C284.207 56.8704 313.59 78.8594 313.59 78.8594C313.59 78.8594 342.953 56.8487 334.122 45.4301Z"
				fill="#E80800"
			/>
			<path
				d="M128.631 254.816H100.073L91.1776 261.994L82.2819 254.816H53.7463C51.9845 254.816 50.5491 253.381 50.5491 251.619V190.633C50.5491 188.871 51.9845 187.436 53.7463 187.436H128.652C130.414 187.436 131.85 188.871 131.85 190.633V251.619C131.828 253.381 130.392 254.816 128.631 254.816Z"
				fill="white"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M111.709 207.859C102.857 196.418 91.1775 210.447 91.1775 210.447C91.1775 210.447 79.4979 196.44 70.6458 207.859C61.7937 219.299 91.1775 241.288 91.1775 241.288C91.1775 241.288 120.561 219.299 111.709 207.859Z"
				fill="#E80800"
			/>
			<path
				d="M457.291 211.642H428.733L419.838 218.82L410.942 211.642H382.385C380.623 211.642 379.188 210.207 379.188 208.445V147.459C379.188 145.697 380.623 144.262 382.385 144.262H457.291C459.052 144.262 460.488 145.697 460.488 147.459V208.445C460.488 210.207 459.052 211.642 457.291 211.642Z"
				fill="white"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
			/>
			<path
				d="M440.369 164.684C431.517 153.244 419.838 167.272 419.838 167.272C419.838 167.272 408.158 153.265 399.306 164.684C390.454 176.124 419.838 198.113 419.838 198.113C419.838 198.113 449.222 176.124 440.369 164.684Z"
				fill="#E80800"
			/>
			<path
				d="M120.431 335.246L124.803 344.816L133.35 338.704L132.35 349.166L142.811 348.165L136.722 356.735L146.27 361.085L136.722 365.456L142.811 374.004L132.35 373.004L133.35 383.465L124.803 377.375L120.431 386.923L116.081 377.375L107.512 383.465L108.512 373.004L98.0505 374.004L104.162 365.456L94.5923 361.085L104.162 356.735L98.0505 348.165L108.512 349.166L107.512 338.704L116.081 344.816L120.431 335.246Z"
				fill="#231F20"
				stroke="#231F20"
				stroke-width="1.08749"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M151.555 7.89062L153.926 14.0675L160.103 16.4383L153.926 18.809L151.555 24.9859L149.162 18.8307L143.007 16.4383L149.184 14.0675L151.555 7.89062Z"
				fill="#231F20"
			/>
			<path
				d="M48.1565 111.158L50.5273 117.335L56.7042 119.706L50.5273 122.077L48.1565 128.253L45.7641 122.098L39.6089 119.706L45.7858 117.335L48.1565 111.158Z"
				fill="#231F20"
			/>
			<path
				d="M343.583 309.429L345.976 315.606L352.131 317.976L345.976 320.369L343.583 326.524L341.212 320.369L335.036 317.976L341.212 315.606L343.583 309.429Z"
				fill="#231F20"
			/>
			<path
				d="M99.7251 421.483L102.096 427.66L108.273 430.031L102.096 432.402L99.7251 438.579L97.3544 432.402L91.1775 430.031L97.3544 427.66L99.7251 421.483Z"
				fill="#231F20"
			/>
			<path
				d="M460.488 51.3467L462.859 57.5236L469.036 59.8943L462.859 62.265L460.488 68.442L458.117 62.265L451.94 59.8943L458.117 57.5236L460.488 51.3467Z"
				fill="#231F20"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1680_4025">
				<rect width="484" height="439" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
