<template>
	<svg width="169" height="81" viewBox="0 0 169 81" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 16.4787L27.4655 8.44531V63.8644L0 16.4787Z" fill="#CDF465" />
		<path d="M50.3358 43.3516L69.356 47.6749L50.9735 80.681L50.3358 43.3516Z" fill="#CDF465" />
		<path
			d="M137.428 0L169 2.88018L145.178 44.4811H120.077L91.9766 72.3127L105.859 26.9773L129.956 27.7278L137.428 0Z"
			fill="#CDF465"
		/>
	</svg>
</template>
