<template>
	<svg width="163" height="160" viewBox="0 0 163 160" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1399_794)">
			<path
				d="M1.409 49.9558V110.039C1.409 121.918 8.71378 132.573 19.7928 136.856L71.0089 156.655C77.6794 159.234 85.072 159.234 91.7424 156.655L142.959 136.856C154.038 132.573 161.342 121.918 161.342 110.039V49.9558C161.342 38.0776 154.038 27.4221 142.959 23.1394L91.7424 3.34033C85.072 0.761558 77.6794 0.761558 71.0089 3.34033L19.7928 23.1394C8.71331 27.4221 1.409 38.0776 1.409 49.9558Z"
				fill="#18191B"
				stroke="white"
				stroke-width="2.8173"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.39349 32.4258L81.3757 59.3934L155.111 34.3007"
				stroke="white"
				stroke-width="2.8173"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M39.9908 42.3681L119.736 15.2305"
				stroke="white"
				stroke-width="2.8173"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M81.3757 59.3945V157.559"
				stroke="white"
				stroke-width="2.8173"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M105.299 122.224L117.806 117.389"
				stroke="white"
				stroke-width="2.8173"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M138.536 44.9524V72.6615C138.536 74.1673 137.595 75.513 136.18 76.0286L116.363 83.2517C114.025 84.1039 111.552 82.3727 111.552 79.8845V51.6956C111.552 50.1611 112.528 48.7971 113.981 48.3026L133.798 41.559C136.123 40.7682 138.536 42.4967 138.536 44.9524Z"
				stroke="white"
				stroke-width="2.8173"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1399_794">
				<rect width="162.751" height="160" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
